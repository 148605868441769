import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faUser, faLock, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../components/common/buttons/ActionButton';
import FormField from '../../components/common/inputs/FormField';
import PageHeader from '../../components/common/headers/PageHeader';
import ForgetPasswordBtn from '../../components/common/buttons/ForgetPasswordBtn';
import { useAuthHook } from '../../hooks/useAuth'; // Import the useAuthHook
import logo from '../../assets/images/logo_login.svg';
import './LoginPage.css';

/**
 * LoginPage Component
 * 
 * This component renders the login page and handles the login process.
 */
const LoginPage = () => {
  // State hooks for form fields and UI states
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  
  // Hook for programmatic navigation
  const navigate = useNavigate();

  // Custom authentication hook
  const { handleLogin, loading, error } = useAuthHook();

  /**
   * Handles the login process
   * 
   * @param {Event} e - The form submit event
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    
    const result = await handleLogin(userId, password);
    
    if (result) {
      // Navigate to the appropriate portal based on the user's role
      navigate(result.role === 'admin' ? '/admin-portal' : '/portal');
    }
    // If login fails, error will be set by handleLogin and displayed below
  };

  /**
   * Navigates to the password reset page
   */
  const handleForgetPassword = () => {
    navigate('/reset-password');
  };

  return (
    <div className="login-page">
      <div className="login-container">
        {/* Page header with logo and title */}
        <PageHeader logo={logo} title="Pups English Portal" />
        
        <div className="login-body">
          {/* Display login error if any */}
          {error && <div className="alert alert-danger">{error}</div>}
          
          {/* Login form */}
          <form onSubmit={onSubmit}>
            {/* User ID input field */}
            <FormField
              icon={faUser}
              type="text"
              placeholder="User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            
            {/* Password input field */}
            <FormField
              icon={faLock}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            
            {/* Login button */}
            <div className="d-grid">
              <ActionButton
                icon={faSignInAlt}
                text={loading ? 'Logging in...' : 'Login'}
                type="submit"
                disabled={loading}
              />
            </div>
          </form>
          
          {/* Forget password button */}
          <ForgetPasswordBtn onClick={handleForgetPassword} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;