import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../components/common/buttons/ActionButton';
import PageHeader from '../../components/common/headers/PageHeader';
import Sidebar from '../../components/layout/Sidebar/Sidebar';
import logo from '../../assets/images/logo_login.jpg';
import './PortalPage.css';

const PortalPage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Here you would typically clear any authentication tokens or user data
    // For now, we'll just redirect to the login page
    navigate('/');
  };

  return (
    <div className="portal-page">
      <Sidebar />
      <div className="portal-content">
        <PageHeader logo={logo} title="Pups English Portal" />
        <div className="main-content">
          <h1>Welcome to Pups Portal</h1>
          <p>This is your main portal page. More content will be added here.</p>
        </div>
        <div className="logout-button">
          <ActionButton icon={faSignOutAlt} text="Logout" onClick={handleLogout} />
        </div>
      </div>
    </div>
  );
};

export default PortalPage;