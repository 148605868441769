import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCalendar, faVideo, faComment, faNewspaper, faUser, faSignInAlt, faUserPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../assets/images/login.jpg';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="Pups Portal Logo" className="logo-image" />
        <h1>Pups Portal</h1>
      </div>
      <nav className="sidebar-nav">
        <ul>
          <li><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /> Dashboard</Link></li>
          <li><Link to="/schedule"><FontAwesomeIcon icon={faCalendar} /> Class Schedule</Link></li>
          <li><Link to="/zoom-links"><FontAwesomeIcon icon={faVideo} /> Zoom Links</Link></li>
          <li><Link to="/feedbacks"><FontAwesomeIcon icon={faComment} /> Feedbacks</Link></li>
          <li><Link to="/news"><FontAwesomeIcon icon={faNewspaper} /> News</Link></li>
        </ul>
      </nav>
      <div className="sidebar-account">
        <h2>Account Pages</h2>
        <ul>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
          <li><Link to="/signin"><FontAwesomeIcon icon={faSignInAlt} /> Sign In</Link></li>
          <li><Link to="/signup"><FontAwesomeIcon icon={faUserPlus} /> Sign Up</Link></li>
        </ul>
      </div>
      <div className="sidebar-help">
        <h3><FontAwesomeIcon icon={faQuestionCircle} /> Need help?</h3>
        <p>Check our wiki</p>
        <button onClick={() => window.open('/wiki', '_blank')}>WIKI</button>
      </div>
    </div>
  );
};

export default Sidebar;