// src/features/UsersPage/UsersView.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus, 
  faUsers, 
  faUserShield, 
  faPencilAlt, 
  faCheck, 
  faEllipsisV, 
  faEye, 
  faEyeSlash, 
  faTimes,
  faBan, // Added for disable account functionality
  faTrash // Added for delete account functionality
} from '@fortawesome/free-solid-svg-icons';
import NewUserModal from './NewUserModal';
import './UsersView.css';

/**
 * UsersView Component
 * 
 * A comprehensive user management interface that allows administrators to:
 * - View all users
 * - Filter between students and admins
 * - Edit user details
 * - Add new users
 * - Update user information
 * - Disable/Delete users through dropdown menu
 */
const UsersView = () => {
  // State Management
  const [activeTab, setActiveTab] = useState('active');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [message, setMessage] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [showPassword, setShowPassword] = useState({});
  const [openMenuId, setOpenMenuId] = useState(null); // New state for tracking open dropdown menu

  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  // Effect to handle clicking outside of dropdown menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openMenuId && !event.target.closest('.action-btn')) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openMenuId]);

  /**
   * Fetches the complete list of users from the backend
   */
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'get_users_for_management' }),
      });
      
      const data = await response.json();
      
      if (response.ok && data.status === 200) {
        setUsers(data.users);
        filterUsers(data.users, activeTab);
      } else {
        setMessage({ 
          type: 'error', 
          text: `Failed to fetch users: ${data.message || 'Unknown error'}`
        });
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setMessage({ 
        type: 'error', 
        text: `Error fetching users: ${error.message || 'Unknown error'}`
      });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles toggling the dropdown menu
   * @param {string} userId - The ID of the user whose menu is being toggled
   */
  const handleMenuToggle = (userId) => {
    setOpenMenuId(openMenuId === userId ? null : userId);
  };

  /**
   * Updates a user's information in the database
   * @param {string} userId - The ID of the user being updated
   */
  const handleSave = async (userId) => {
    setIsLoading(true);
    setMessage(null);
    
    try {
      const response = await fetch('/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'update_user',
          user_id: userId,
          email: editedData.email,
          contact_name: editedData.contact_name,
          contact_surname: editedData.contact_surname,
          student_name: editedData.student_name,
          student_surname: editedData.student_surname,
          ...(editedData.password && { password: editedData.password })
        }),
      });

      const data = await response.json();

      if (response.ok && data.status === 200) {
        setMessage({ type: 'success', text: 'User updated successfully!' });
        await fetchUsers();
        setEditingUser(null);
      } else {
        setMessage({ type: 'error', text: data.message || 'Failed to update user' });
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setMessage({ 
        type: 'error', 
        text: `Error updating user: ${error.message}`
      });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Filters users based on selected tab
   * @param {Array} allUsers - Complete list of users
   * @param {string} tab - Selected tab ('active' or 'admins')
   */
  const filterUsers = (allUsers, tab) => {
    if (tab === 'active') {
      setFilteredUsers(allUsers.filter(user => user.role === 'student'));
    } else if (tab === 'admins') {
      setFilteredUsers(allUsers.filter(user => user.role === 'admin'));
    }
  };

  /**
   * Handles adding a new user
   * @param {Object} userData - New user's information
   */
  const handleAddUser = async (userData) => {
    setMessage(null);
    setIsAddingUser(true);
    
    try {
      const response = await fetch('/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          action: 'create_user',
          ...userData
        }),
      });
      
      const data = await response.json();
      
      if (response.ok && data.status === 201) {
        setIsModalOpen(false);
        await fetchUsers();
        setMessage({ type: 'success', text: 'User created successfully!' });
      } else {
        setMessage({ 
          type: 'error', 
          text: `Failed to create user: ${data.message || 'Unknown error'}`
        });
      }
    } catch (error) {
      console.error('Error creating user:', error);
      setMessage({ 
        type: 'error', 
        text: `Error creating user: ${error.message || 'Unknown error'}`
      });
    } finally {
      setIsAddingUser(false);
    }
  };

  const handleMenuClick = (tab) => {
    setActiveTab(tab);
    filterUsers(users, tab);
  };

  const handleEdit = (user) => {
    setEditingUser(user.user_id);
    setEditedData(user);
  };

  const handleInputChange = (e, field) => {
    setEditedData({ ...editedData, [field]: e.target.value });
  };

  const togglePasswordVisibility = (userId) => {
    setShowPassword(prev => ({ ...prev, [userId]: !prev[userId] }));
  };

  const handleCancelEdit = () => {
    setEditingUser(null);
    setEditedData({});
  };

  /**
   * Renders a table row for a user
   * @param {Object} user - The user data to render
   */
  const renderUserRow = (user) => {
    const isEditing = editingUser === user.user_id;

    return (
      <tr key={user.user_id}>
        {/* Contact Name Column */}
        <td>
          {isEditing ? (
            <div>
              <input
                value={editedData.contact_name || ''}
                onChange={(e) => handleInputChange(e, 'contact_name')}
                placeholder="Contact name"
              />
              <input
                value={editedData.contact_surname || ''}
                onChange={(e) => handleInputChange(e, 'contact_surname')}
                placeholder="Contact surname"
              />
            </div>
          ) : (
            `${user.contact_name} ${user.contact_surname}`
          )}
        </td>

        {/* User ID Column */}
        <td>
          <input
            value={user.user_id}
            disabled
            className="disabled-input"
          />
        </td>

        {/* Student Name Column */}
        <td>
          {isEditing ? (
            <div>
              <input
                value={editedData.student_name || ''}
                onChange={(e) => handleInputChange(e, 'student_name')}
                placeholder="Student name"
              />
              <input
                value={editedData.student_surname || ''}
                onChange={(e) => handleInputChange(e, 'student_surname')}
                placeholder="Student surname"
              />
            </div>
          ) : (
            user.student_full_name
          )}
        </td>

        {/* Email Column */}
        <td>
          {isEditing ? (
            <input
              value={editedData.email || ''}
              onChange={(e) => handleInputChange(e, 'email')}
              type="email"
            />
          ) : (
            user.email
          )}
        </td>

        {/* Role Column */}
        <td>
          <input
            value={user.role}
            disabled
            className="disabled-input"
          />
        </td>

        {/* Password Column */}
        <td>
          {isEditing ? (
            <div className="password-input">
              <input
                type={showPassword[user.user_id] ? 'text' : 'password'}
                value={editedData.password || ''}
                onChange={(e) => handleInputChange(e, 'password')}
                placeholder="New password (optional)"
              />
              <button
                className="password-toggle"
                onClick={() => togglePasswordVisibility(user.user_id)}
                type="button"
              >
                <FontAwesomeIcon 
                  icon={showPassword[user.user_id] ? faEyeSlash : faEye} 
                />
              </button>
            </div>
          ) : (
            '********'
          )}
        </td>

        {/* Actions Column */}
        <td>
          {isEditing ? (
            <>
              <button 
                className="action-btn" 
                onClick={() => handleSave(user.user_id)}
                title="Save changes"
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <button 
                className="action-btn" 
                onClick={handleCancelEdit}
                title="Cancel editing"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </>
          ) : (
            <>
              <button 
                className="action-btn" 
                onClick={() => handleEdit(user)}
                title="Edit user"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <button 
                  className="action-btn" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuToggle(user.user_id);
                  }}
                  title="More options"
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </button>
                {/* Dropdown Menu */}
                {openMenuId === user.user_id && (
                  <div className="action-menu">
                    <button 
                      className="menu-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        // TODO: Implement disable account functionality
                        setOpenMenuId(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faBan} />
                      <span>Disable account</span>
                    </button>
                    <button 
                      className="menu-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        // TODO: Implement delete account functionality
                        setOpenMenuId(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      <span>Delete account</span>
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </td>
      </tr>
    );
  };

  // Component Render
  return (
    <div className="users-view">
      {/* Sidebar */}
      <div className="users-sidebar">
        <button 
          className="new-account-btn"
          onClick={() => setIsModalOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} /> New account
        </button>

        <button 
          className={`sidebar-btn ${activeTab === 'active' ? 'active' : ''}`}
          onClick={() => handleMenuClick('active')}
        >
          <FontAwesomeIcon icon={faUsers} /> Active accounts
        </button>

        <button 
          className={`sidebar-btn ${activeTab === 'admins' ? 'active' : ''}`}
          onClick={() => handleMenuClick('admins')}
        >
          <FontAwesomeIcon icon={faUserShield} /> Admins
        </button>
      </div>

      {/* Main Content */}
      <div className="users-content">
        <h2>Users Management</h2>

        {/* Messages */}
        {message && (
          <div className={`message ${message.type}`}>{message.text}</div>
        )}

        {/* Users Table */}
        {isLoading ? (
          <p>Loading users...</p>
        ) : filteredUsers.length > 0 ? (
          <table className="users-table">
            <thead>
              <tr>
                <th>Contact name</th>
                <th>Username</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Password</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(renderUserRow)}
            </tbody>
          </table>
        ) : (
          <p>No users found.</p>
        )}
      </div>

      {/* Modals and Overlays */}
      <NewUserModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddUser={handleAddUser}
      />

      {isAddingUser && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Adding user...</p>
        </div>
      )}
    </div>
  );
};

export default UsersView;