import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ActionButton.module.css';

const ActionButton = ({ icon, text, type = 'button', onClick }) => (
  <button type={type} className={styles.button} onClick={onClick}>
    <FontAwesomeIcon icon={icon} className={styles.icon} /> {text}
  </button>
);

export default ActionButton;
