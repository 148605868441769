import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import FormField from '../../components/common/inputs/FormField';
import ActionButton from '../../components/common/buttons/ActionButton';
import { useAuth } from '../../context/AuthContext';
import './SecurityView.css';

/**
 * SecurityView Component
 * 
 * This component provides a user interface for changing the user's password.
 * It includes form fields for the current password and new password,
 * and handles the password change process.
 */
const SecurityView = () => {
  // State for form fields
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  
  // State for displaying messages and managing loading state
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  // Get the current user from the authentication context
  const { user } = useAuth();

  /**
   * Handles the password change process
   * 
   * @param {Event} e - The form submission event
   */
  const handleChangePassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    try {
      // Send a request to the backend to change the password
      const response = await fetch('/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'change_password',
          user_id: user.user_id,
          current_password: currentPassword,
          new_password: newPassword,
        }),
      });

      const data = await response.json();
      setMessage(data.message);
      
      if (response.ok) {
        // Clear the form fields on successful password change
        setCurrentPassword('');
        setNewPassword('');
      }
    } catch (error) {
      console.error('Password change error:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="security-view">
      <h2>Security Settings</h2>
      {/* Display any messages (success or error) */}
      {message && (
        <div className={`alert ${message.includes('successfully') ? 'alert-success' : 'alert-danger'}`}>
          {message}
        </div>
      )}
      <form onSubmit={handleChangePassword}>
        {/* Input field for current password */}
        <FormField
          icon={faLock}
          type="password"
          placeholder="Current password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        {/* Input field for new password */}
        <FormField
          icon={faLock}
          type="password"
          placeholder="New password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        {/* Submit button for changing password */}
        <ActionButton
          icon={faLock}
          text={isLoading ? "Changing Password..." : "Change Password"}
          type="submit"
          disabled={isLoading}
        />
      </form>
    </div>
  );
};

export default SecurityView;