import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import './NewUserModal.css';

/**
 * NewUserModal Component
 * 
 * This component renders a modal for creating a new user.
 * It includes form fields for all user data and handles the user creation process.
 * 
 * @param {Object} props - The component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to close the modal
 * @param {Function} props.onAddUser - Function to call after successfully adding a user
 */
const NewUserModal = ({ isOpen, onClose, onAddUser }) => {
  // State to hold user data, aligned with the database schema
  const [userData, setUserData] = useState({
    user_id: '',
    email: '',
    password: '',
    contact_name: '',
    contact_surname: '',
    student_name: '',
    student_surname: '',
    role: 'student' // Default role set to 'student'
  });

  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);

  // State to handle form submission status and errors
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  /**
   * Handles changes in form inputs
   * 
   * @param {Event} e - The input change event
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({ ...prevData, [name]: value }));
  };

  /**
   * Toggles password visibility
   */
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Handles form submission
   * 
   * @param {Event} e - The form submit event
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      // Call the onAddUser function passed as a prop
      await onAddUser(userData);
      // The modal will be closed by the parent component after successful user creation
    } catch (error) {
      console.error('Error creating user:', error);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // If modal is not open, don't render anything
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* Modal header */}
        <div className="modal-header">
          <h2>New user</h2>
          <button className="close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        
        {/* User creation form */}
        <form onSubmit={handleSubmit}>
          {/* Display error message if there's an error */}
          {error && <div className="error-message">{error}</div>}

          {/* User ID input */}
          <div className="form-group">
            <input
              type="text"
              id="user_id"
              name="user_id"
              placeholder="User ID (required)"
              value={userData.user_id}
              onChange={handleChange}
              required
            />
          </div>

          {/* Email input */}
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email (required)"
              value={userData.email}
              onChange={handleChange}
              required
            />
          </div>

          {/* Password input with toggle visibility */}
          <div className="form-group">
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password (required)"
                value={userData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
          </div>

          {/* Contact First Name input */}
          <div className="form-group">
            <input
              type="text"
              id="contact_name"
              name="contact_name"
              placeholder="Contact First Name"
              value={userData.contact_name}
              onChange={handleChange}
            />
          </div>

          {/* Contact Last Name input */}
          <div className="form-group">
            <input
              type="text"
              id="contact_surname"
              name="contact_surname"
              placeholder="Contact Last Name"
              value={userData.contact_surname}
              onChange={handleChange}
            />
          </div>

          {/* Student First Name input */}
          <div className="form-group">
            <input
              type="text"
              id="student_name"
              name="student_name"
              placeholder="Student First Name"
              value={userData.student_name}
              onChange={handleChange}
            />
          </div>

          {/* Student Last Name input */}
          <div className="form-group">
            <input
              type="text"
              id="student_surname"
              name="student_surname"
              placeholder="Student Last Name"
              value={userData.student_surname}
              onChange={handleChange}
            />
          </div>

          {/* Role dropdown */}
          <div className="form-group">
            <select
              id="role"
              name="role"
              value={userData.role}
              onChange={handleChange}
            >
              <option value="student">Student</option>
              <option value="admin">Admin</option>
            </select>
          </div>

          {/* Form submission button */}
          <div className="form-actions">
            <button 
              type="submit" 
              className="btn-primary" 
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Adding user...' : 'Add new user'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewUserModal;