import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { useAuth } from './context/AuthContext';
import LoginPage from './pages/LoginPage/LoginPage';
import PasswordResetPage from './pages/PasswordResetPage/PasswordResetPage';
import PortalPage from './pages/PortalPage/PortalPage';
import AdminPortalPage from './pages/AdminPortalPage/AdminPortalPage';
import 'bootstrap/dist/css/bootstrap.min.css';

// ProtectedRoute component to handle authentication and authorization
const ProtectedRoute = ({ element: Element, allowedRoles, ...rest }) => {
  // Use the custom hook to access the current user's authentication state
  const { user } = useAuth();
  
  // Check if the user is authenticated and has an allowed role
  if (user && allowedRoles.includes(user.role)) {
    // If authenticated and authorized, render the protected component
    return <Element {...rest} />;
  } else {
    // If not authenticated or not authorized, redirect to the login page
    return <Navigate to="/" replace />;
  }
};

function App() {
  return (
    // Wrap the entire application with the AuthProvider to make auth state globally available
    <AuthProvider>
      {/* Set up the router */}
      <Router>
        <div className="App">
          {/* Define the routes */}
          <Routes>
            {/* Login page - accessible to all */}
            <Route path="/" element={<LoginPage />} />
            
            {/* Password reset page - accessible to all */}
            <Route path="/reset-password" element={<PasswordResetPage />} />
            
            {/* Portal page - protected, accessible to both students and admins */}
            <Route 
              path="/portal" 
              element={
                <ProtectedRoute 
                  element={PortalPage} 
                  allowedRoles={['student', 'admin']} 
                />
              } 
            />
            
            {/* Admin portal page - protected, accessible only to admins */}
            <Route 
              path="/admin-portal" 
              element={
                <ProtectedRoute 
                  element={AdminPortalPage} 
                  allowedRoles={['admin']} 
                />
              } 
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;