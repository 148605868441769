// src/components/common/menus/UserMenu.js

import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faUserCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import './UserMenu.css';

/**
 * UserMenu Component
 * 
 * This component renders a user menu with options to view profile, access users page, and logout.
 * 
 * @param {Object} props - The component props
 * @param {string} props.username - The username to display
 * @param {Function} props.onLogout - Function to handle logout
 * @param {Function} props.onViewProfile - Function to handle view profile action
 * @param {Function} props.onViewUsers - Function to handle view users action
 */
const UserMenu = ({ username, onLogout, onViewProfile, onViewUsers }) => {
  // State to manage menu open/closed
  const [isOpen, setIsOpen] = useState(false);
  
  // Ref for the menu container to detect outside clicks
  const menuRef = useRef(null);

  // Toggle menu open/closed
  const toggleMenu = () => setIsOpen(!isOpen);

  // Effect to handle clicking outside the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle view profile action
  const handleViewProfile = () => {
    onViewProfile();
    setIsOpen(false);
  };

  // Handle view users action
  const handleViewUsers = () => {
    onViewUsers();
    setIsOpen(false);
  };

  return (
    <div className="user-menu" ref={menuRef}>
      <button className="user-menu-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faUser} />
      </button>
      {isOpen && (
        <div className="user-menu-dropdown">
          <div className="user-info">
            <FontAwesomeIcon icon={faUserCircle} className="user-avatar" />
            <span className="username">{username}</span>
          </div>
          <ul>
            <li>
              <button onClick={handleViewProfile}>
                <FontAwesomeIcon icon={faUser} />
                <span>View profile</span>
              </button>
            </li>
            {/* New Users menu item */}
            <li>
              <button onClick={handleViewUsers}>
                <FontAwesomeIcon icon={faUsers} />
                <span>Users</span>
              </button>
            </li>
            <li>
              <button onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Log out</span>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserMenu;