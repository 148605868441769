// src/pages/AdminPortalPage/AdminPortalPage.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideMenu from '../../components/layout/Sidebar/SideMenu';
import NavBar from '../../components/layout/NavBar/NavBar';
import ProfileView from '../../features/ProfilePage/ProfileView';
import SecurityView from '../../features/SecurityPage/SecurityView';
import UsersView from '../../features/UsersPage/UsersView';
import { useAuth } from '../../context/AuthContext';
import { useAuthHook } from '../../hooks/useAuth';
import './AdminPortalPage.css';

const AdminPortalPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { handleLogout } = useAuthHook();
  const [activeView, setActiveView] = useState('dashboard');
  const [showSidebar, setShowSidebar] = useState(false);

  // Effect to check if the user is authenticated and is an admin
  useEffect(() => {
    if (!user || user.role !== 'admin') {
      navigate('/login'); // Redirect to login if not authenticated or not an admin
    }
  }, [user, navigate]);

  // Effect to control sidebar visibility
  useEffect(() => {
    // Hide the original sidebar when Users view is active
    setShowSidebar(['profile', 'security'].includes(activeView));
  }, [activeView]);

  // Function to handle user logout
  const onLogout = () => {
    handleLogout();
    navigate('/');
  };

  // Function to handle side menu item clicks
  const handleMenuClick = (view) => {
    setActiveView(view);
  };

  // Function to handle view profile action
  const handleViewProfile = () => {
    setActiveView('profile');
  };

  // Function to handle view users action
  const handleViewUsers = () => {
    setActiveView('users');
  };

  // Function to handle dashboard button click
  const handleDashboardClick = () => {
    setActiveView('dashboard');
  };

  // If there's no user (still loading) or not an admin, don't render the page
  if (!user || user.role !== 'admin') {
    return null;
  }

  return (
    <div className="admin-portal-page">
      <NavBar 
        onDashboardClick={handleDashboardClick}
        username={user.user_id}
        onLogout={onLogout}
        onViewProfile={handleViewProfile}
        onViewUsers={handleViewUsers}
      />
      <div className="admin-portal-content">
        {showSidebar && <SideMenu onMenuClick={handleMenuClick} activeView={activeView} />}
        <div className={`main-content ${activeView === 'users' ? 'full-width' : ''}`}>
          {activeView !== 'users' && (
            <div className="header-container">
              <h1>Admin Portal</h1>
            </div>
          )}
          {activeView === 'dashboard' && (
            <p>Welcome to the Admin Portal, {user.full_name || user.user_id}. Add your admin-specific content here.</p>
          )}
          {activeView === 'profile' && (
            <ProfileView user={user} />
          )}
          {activeView === 'security' && (
            <SecurityView />
          )}
          {activeView === 'users' && (
            <UsersView />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPortalPage;