// src/components/layout/NavBar/NavBar.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import UserMenu from '../../common/menus/UserMenu';
import logo from '../../../assets/images/logo_login.svg';
import './NavBar.css';

/**
 * NavBar Component
 * 
 * This component renders the top navigation bar with logo, dashboard button, and user menu.
 * 
 * @param {Object} props - The component props
 * @param {Function} props.onDashboardClick - Function to handle dashboard button click
 * @param {string} props.username - The username to display in the UserMenu
 * @param {Function} props.onLogout - Function to handle user logout
 * @param {Function} props.onViewProfile - Function to handle view profile action
 * @param {Function} props.onViewUsers - Function to handle view users action
 */
const NavBar = ({ onDashboardClick, username, onLogout, onViewProfile, onViewUsers }) => {
  return (
    <nav className="nav-bar">
      <div className="nav-left">
        {/* Logo */}
        <img src={logo} alt="Portal Logo" className="nav-logo" />
        
        {/* Dashboard button */}
        <button className="dashboard-btn" onClick={onDashboardClick}>
          <FontAwesomeIcon icon={faTachometerAlt} />
          <span>Dashboard</span>
        </button>
      </div>
      
      {/* User Menu */}
      <div className="nav-right">
        <UserMenu
          username={username}
          onLogout={onLogout}
          onViewProfile={onViewProfile}
          onViewUsers={onViewUsers}
        />
      </div>
    </nav>
  );
};

export default NavBar;