import React from 'react';
import styles from './PageHeader.module.css';

const PageHeader = ({ logo, title }) => (
  <div className={styles.header}>
    <img src={logo} alt={`${title} Logo`} className={styles.logo} />
  </div>
);

export default PageHeader;

