import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FormField.module.css';

const FormField = ({ icon, type, placeholder, value, onChange }) => (
  <div className={styles.inputGroup}>
    <span className={styles.inputGroupText}>
      <FontAwesomeIcon icon={icon} />
    </span>
    <input
      type={type}
      className={styles.formControl}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required
    />
  </div>
);

export default FormField;
