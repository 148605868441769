// src/features/ProfilePage/ProfileView.js

import React from 'react';
import './ProfileView.css';

/**
 * ProfileView Component
 * 
 * This component displays user profile information.
 * 
 * @param {Object} props - The component props
 * @param {Object} props.user - The user object containing profile information
 */
const ProfileView = ({ user }) => {
  return (
    <div className="profile-view">
      <h2>My Profile</h2>
      <div className="profile-info">
        <p><strong>Username:</strong> {user.user_id}</p>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Role:</strong> {user.role}</p>
        {/* Add more profile information as needed */}
      </div>
    </div>
  );
};

export default ProfileView;
