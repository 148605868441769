import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { faUser, faLock, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../components/common/buttons/ActionButton';
import FormField from '../../components/common/inputs/FormField';
import PageHeader from '../../components/common/headers/PageHeader';
import LoginContainer from '../../components/common/containers/LoginContainer';
import logo from '../../assets/images/logo_login.svg';
import './PasswordResetPage.css';

const PasswordResetPage = () => {
  const [userId, setUserId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetStage, setResetStage] = useState('request'); // 'request' or 'reset'
  const navigate = useNavigate();
  const location = useLocation();

  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    if (token) {
      setResetStage('reset');
    }
  }, [token]);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    try {
      const response = await fetch('/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'forgot_password',
          user_id: userId,
        }),
      });

      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      console.error('Forgot password error:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'reset_password',
          token: token,
          new_password: newPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message);
        setTimeout(() => navigate('/'), 3000); // Redirect to login page after 3 seconds
      } else {
        setMessage(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Password reset error:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="password-reset-page">
      <LoginContainer header={<PageHeader logo={logo} title="Reset Password" />}>
        {message && <div className="alert alert-info">{message}</div>}
        {resetStage === 'request' ? (
          <form onSubmit={handleForgotPassword}>
            <FormField
              icon={faUser}
              type="text"
              placeholder="User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            <div className="d-grid gap-2">
              <ActionButton 
                icon={faUser} 
                text="Request Password Reset" 
                type="submit" 
                disabled={isLoading}
              />
              <ActionButton 
                icon={faArrowLeft} 
                text="Back to Login" 
                onClick={() => navigate('/')} 
                variant="secondary" 
                disabled={isLoading}
              />
            </div>
          </form>
        ) : (
          <form onSubmit={handleResetPassword}>
            <FormField
              icon={faLock}
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <FormField
              icon={faLock}
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className="d-grid gap-2">
              <ActionButton 
                icon={faLock} 
                text="Reset Password" 
                type="submit" 
                disabled={isLoading || !token}
              />
              <ActionButton 
                icon={faArrowLeft} 
                text="Back to Login" 
                onClick={() => navigate('/')} 
                variant="secondary" 
                disabled={isLoading}
              />
            </div>
          </form>
        )}
      </LoginContainer>
    </div>
  );
};

export default PasswordResetPage;