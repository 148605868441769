import { useState } from 'react';
import { useAuth } from '../context/AuthContext';

export const useAuthHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login, logout } = useAuth();

  const handleLogin = async (user_id, password) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'login', user_id, password }),
      });
      const data = await response.json();
      if (response.ok) {
        login(data);
        return data;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
  };

  return { handleLogin, handleLogout, loading, error };
};