// src/components/layout/Sidebar/SideMenu.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faUsers } from '@fortawesome/free-solid-svg-icons';
import './SideMenu.css';

/**
 * SideMenu Component
 * 
 * This component renders the side navigation menu.
 * 
 * @param {Object} props - The component props
 * @param {Function} props.onMenuClick - Function to handle menu item clicks
 * @param {string} props.activeView - The currently active view
 */
const SideMenu = ({ onMenuClick, activeView }) => {
  return (
    <div className="side-menu">
      <div 
        className={`menu-item ${activeView === 'profile' ? 'active' : ''}`}
        onClick={() => onMenuClick('profile')}
      >
        <FontAwesomeIcon icon={faUser} className="menu-icon" />
        <span>My Profile</span>
      </div>
      <div 
        className={`menu-item ${activeView === 'security' ? 'active' : ''}`}
        onClick={() => onMenuClick('security')}
      >
        <FontAwesomeIcon icon={faLock} className="menu-icon" />
        <span>Security</span>
      </div>
      {/* New Users menu item */}
      <div 
        className={`menu-item ${activeView === 'users' ? 'active' : ''}`}
        onClick={() => onMenuClick('users')}
      >
        <FontAwesomeIcon icon={faUsers} className="menu-icon" />
        <span>Users</span>
      </div>
    </div>
  );
};

export default SideMenu;