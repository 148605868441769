import React from 'react';
import './LoginContainer.css';

const LoginContainer = ({ children, header }) => {
  return (
    <div className="login-container">
      {header}
      <div className="login-body">
        {children}
      </div>
    </div>
  );
};

export default LoginContainer;
